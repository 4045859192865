import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  Image,
  Stack,
  useColorModeValue,
  Button,
  SimpleGrid,
  GridItem,
  Link,
} from "@chakra-ui/react";
import Values from "../images/4.png";
import Vision from "../images/5.png";
import Mission from "../images/6.png";

const Services = () => {
  const bgColor = useColorModeValue("gray.100", "gray.900");

  // Dummy data for services
  const services = [
    {
      title: "AI Development",
      description:
        "We offer cutting-edge artificial intelligence development services to help businesses leverage the power of AI for automation, prediction, and decision-making.",
      image: Values,
    },
    {
      title: "Software Development",
      description:
        "Our expert software engineers craft robust, scalable, and efficient software solutions tailored to your unique business requirements and goals.",
      image: Vision,
    },
    {
      title: "Architecture Consultancy",
      description:
        "We provide architectural consulting services to help you design and implement scalable, secure, and high-performance software architectures.",
      image: Mission,
    },
    // Add more services here
  ];

  // Function to render a single service card
  const ServiceCard = ({ title, description, image }) => (
    <Box
      p={6}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      bg="white"
      maxW="400px"
      w="100%"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Image src={image} alt={title} mb={4} />
      <Heading as="h3" size="md" mb={4}>
        {title}
      </Heading>
      <Text>{description}</Text>
    </Box>
  );

  return (
    <Box minHeight="100vh" bg={bgColor} padding={[4, 8, 12, 16]}>
      <Flex justifyContent="center" alignItems="center" direction="column">
        <Heading as="h2" size="xl" textAlign="center" pb={8}>
          Services
        </Heading>
        <Text fontSize="lg" textAlign="center" maxW="7xl">
          We are a passionate team driven by a shared mission to bridge the gap
          between cutting-edge artificial intelligence and innovative software
          development. We believe in the power of AI to revolutionize the way we
          build and interact with technology.
        </Text>
      </Flex>

      <Flex justifyContent="center" my={8}>
        <Text fontSize="lg" fontWeight="bold">
          Let's build the future together!
        </Text>
      </Flex>

      {/* Top services section */}
      <SimpleGrid
        columns={[1, 2, 3]}
        spacing={8}
        mb={8}
        justifyItems={"center"}
      >
        {services.slice(0, 3).map((service, index) => (
          <GridItem key={index} colSpan={[1, 1, 1]}>
            <ServiceCard {...service} />
          </GridItem>
        ))}
      </SimpleGrid>

      {/* View more services section */}
      <Box maxW="7xl" mx="auto">
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ]}
          gap={8}
        >
          {services.slice(3).map((service, index) => (
            <GridItem key={index} colSpan={[1, 1, 1]}>
              <ServiceCard {...service} />
            </GridItem>
          ))}
        </Grid>
        <Flex justifyContent="center" mt={6}>
          <Text color={"black"}>
            Discover our wide range of services—reach out to learn more!
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default Services;
