import P1 from "../images/P1.png";
import P2 from "../images/P2.png";
import P3 from "../images/P3.png";
import P4 from "../images/P4.png";
import P5 from "../images/P5.png";

export const projectsData = [
  {
    name: "Ehsaas App",
    date: "2024-01-19",
    image: P1,
    techStack: ["React", "Node.js", "Express"],
    description:
      "Ehsaas App is a modern social media platform that prioritizes user privacy and fosters meaningful connections. With robust features for secure messaging, content sharing, and community building, the app ensures that users can engage with their network without compromising their personal data.",
    clientName: "Ammar Ullah Khan",
    clientRating: 5,
    clientReview:
      "An amazing team that delivered a high-quality product on time and within budget. Highly recommend!",
  },
  {
    name: "Verifi",
    date: "2023-05-04",
    image: P2,
    techStack: ["React", "Node.js", "Express"],
    description:
      "Verifi is a degree verification blockchain-based website designed to provide a secure and tamper-proof way to verify academic credentials. Utilizing cutting-edge blockchain technology, Verifi ensures that educational institutions, employers, and individuals can trust the authenticity of academic qualifications with a transparent and immutable verification process.",
    clientName: "Anonymous",
    clientRating: 5,
    clientReview:
      "I am thoroughly impressed with the exceptional service provided by the Atom1.ai team. They completed our project in record time, far exceeding our expectations. From the initial consultation to the final delivery, their professionalism and expertise were evident at every stage.",
  },
  {
    name: "Speed Coders",
    date: "2023-11-19",
    image: P3,
    techStack: ["React", "Node.js", "Express"],
    description:
      "Speed Coders is a dynamic platform designed for coders and problem solvers, offering a wide range of coding challenges, tutorials, and collaborative projects. The platform aims to enhance coding skills, foster a community of like-minded individuals, and provide opportunities for professional growth through real-world problem-solving and peer interactions.",
    clientName: "Owais Ahmed",
    clientRating: 5,
    clientReview:
      "The team’s dedication to understanding our requirements and their ability to translate our vision into a high-quality product was remarkable. They demonstrated an unparalleled commitment to meeting our tight deadlines without compromising on the quality of the work.",
  },
  {
    name: "JobMatch AI",
    date: "2023-12-01",
    image: P4,
    techStack: ["React", "Python", "OpenAI", "ChromaDB"],
    description:
      "JobMatch AI is a smart recruitment tool that helps companies quickly find the best candidates for their job openings. By using advanced AI technology, it takes in resumes from applicants and matches them to job positions, making sure the most suitable person is chosen for each role. The system uses powerful models and a vector database to analyze and compare resumes with job descriptions, and it's built on a strong Python backend with a user-friendly React interface. With JobMatch AI, companies can save time and effort in their hiring process and easily find the right talent.",
    clientName: "Faizan Sadiq",
    clientRating: 5,
    clientReview:
      "The team completed this project with perfection, ensuring all code was in place and functioning flawlessly. I am extremely satisfied with their work and look forward to continuing our collaboration in the future.",
  },

  {
    name: "Class Connect",
    date: "2024-07-30",
    image: P5,
    techStack: ["React", "Node.js", "ExpressJS", "MongoDB"],
    description:
      "Class Connect is an innovative platform designed to maintain and organize teacher portfolios efficiently. It includes four distinct roles: Teacher, Student, Parent, and Admin. Admins have complete control over the app, managing all aspects seamlessly. Teachers can add their classes and courses, providing structured educational content. Students can view their classes and track their academic progress. Parents can monitor their child's activities and approve courses based on teacher inputs. This comprehensive system ensures a well-organized and collaborative educational environment.",
    clientName: "Anonymous",
    clientRating: 5,
    clientReview: "N/A",
  },
];
