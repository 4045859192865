import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  Stack,
  Card,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";

import { projectsData } from "../data/projects";

const Projects = () => {
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const cardBgColor = bgColor; // Set card background to match the parent background
  const [selectedProject, setSelectedProject] = useState(null);

  const handleCardClick = (project) => {
    setSelectedProject(project);
  };

  return (
    <Box minHeight="100vh" bg={bgColor} padding={[8, 8, 12, 100]}>
      <Flex justifyContent="center" alignItems="center" direction="column">
        <Heading as="h2" size="xl" textAlign="center" pb={8}>
          Our Work
        </Heading>
        <Text fontSize="lg" textAlign="center" maxW="7xl">
          We are a passionate team driven by a shared mission to bridge the gap
          between cutting-edge artificial intelligence and innovative software
          development. We believe in the power of AI to revolutionize the way we
          build and interact with technology.
        </Text>
      </Flex>
      <Flex wrap="wrap" justifyContent="center">
        {projectsData.map((project, index) => (
          <Card
            key={project.name}
            cursor="pointer"
            onClick={() => handleCardClick(project)}
            width={["100%", "calc(50% - 20px)", "calc(30% - 20px)"]}
            maxWidth={["100%", "calc(50% - 20px)", "calc(30% - 20px)"]}
            height="auto"
            mt={5}
            mx={["auto", "10px", "30px"]}
            alignItems={"center"}
            padding={4}
            bg={cardBgColor} // Set card background color
            _hover={{
              bg: "black", // Change color on hover
            }}
          >
            <Stack mt={5} mb={5} spacing={2}>
              <Heading as="h5" size="md" color={"#0874D1"}>
                {project.name}
              </Heading>
            </Stack>
            <Box
              position="relative"
              width="80%"
              height="200px"
              borderRadius={10}
              overflow="hidden"
            >
              <Image
                src={project.image}
                alt={project.name}
                objectFit="cover"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                minWidth="100%"
                minHeight="100%"
              />
            </Box>
            <Text mt={5} mb={5} color={"#0874D1"} fontSize="sm">
              {project.techStack.join(", ")}
            </Text>
            <Button
              as={"a"}
              display={{ base: "none", md: "inline-flex" }}
              fontSize={"sm"}
              fontWeight={600}
              color={"black"}
              bg={"white"}
              borderRadius={25}
              _hover={{
                color: "#000",
              }}
            >
              View Details
            </Button>
          </Card>
        ))}
      </Flex>

      <Modal
        isOpen={selectedProject !== null}
        onClose={() => setSelectedProject(null)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg={"black"}
          maxW={{ base: "100%", md: "70%" }}
          height={{ base: "95%", md: "70%" }}
        >
          <ModalHeader>
            {selectedProject && (
              <Heading color={"white"} as="h5" mt={5} textAlign={"center"}>
                {selectedProject.name}
              </Heading>
            )}
            <ModalCloseButton color="white" />
          </ModalHeader>
          <ModalBody overflowY="auto">
            <Flex
              justifyContent="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap={5}
            >
              <Box
                width={{ base: "100%", md: "70%" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Image
                  src={selectedProject?.image}
                  alt={selectedProject?.name}
                  height="auto"
                  objectFit="cover"
                  borderRadius="md"
                />
                <Text color={"white"} paddingTop={4}>
                  {selectedProject?.techStack.join(", ")}
                </Text>
                <Text color={"white"} paddingTop={4}>
                  {selectedProject?.description}
                </Text>
              </Box>
              <Box
                width={{ base: "100%", md: "30%" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent={"center"}
              >
                <Text color={"#0874D1"} fontWeight="bold">
                  Client Name:
                </Text>
                <Text color={"white"} textAlign={"center"}>
                  {selectedProject?.clientName}
                </Text>
                <Text color={"#0874D1"} mt={10} fontWeight="bold">
                  Client Rating:
                </Text>
                <Rating rating={selectedProject?.clientRating} />
                <Text color={"#0874D1"} mt={10} fontWeight="bold">
                  Client Review:
                </Text>
                <Text color={"white"} textAlign={"center"}>
                  "{selectedProject?.clientReview}"
                </Text>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const Rating = ({ rating }) => {
  const stars = Array(5).fill("☆");
  for (let i = 0; i < rating; i++) {
    stars[i] = "★";
  }
  return (
    <Text color="gold">
      {stars.join(" ")} ({rating})
    </Text>
  );
};

export default Projects;
