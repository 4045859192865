import React from 'react';
import { Box, Heading } from '@chakra-ui/react';


const OurWork = () => {
  return (
    <Box minHeight="100vh" bg="gray.100" padding={40}>
      <Heading as="h2" size="xl" textAlign="center" py={8}>
        Our Work
      </Heading>
      {/* Add your About Us content here */}
    </Box>
  );
};

export default OurWork;