// Footer.js
import React from "react";
import {
  Box,
  Text,
  VStack,
  Link,
  Image,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import LogoImage from "../images/Atom1W.png";

const Footer = () => {
  return (
    <Box as="footer" width="100%" bg="black" py="10vh" bottom="0">
      <Grid
        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        gap={6}
        textAlign="center"
      >
        <GridItem>
          <VStack>
            <Image src={LogoImage} alt="Logo" boxSize="100px" />
            <Text mt={-5} fontSize={"30"} color={"white"}>
              Atom1.ai
            </Text>
            <Text fontSize="l" color={"white"}>
              We Empower Your Business,
              {
                <Text as="span" color="#0874D1">
                  Atom by Atom
                </Text>
              }
            </Text>
          </VStack>
        </GridItem>
        <GridItem>
          <VStack spacing={1}>
            <Text fontWeight="bold" color="#0874D1" fontSize="2xl" mt={5}>
              Let's Connect!
            </Text>
            <Text color="#0874D1" fontSize="small">
              Phone
            </Text>
            <Text color="white" fontSize="large">
              +92 349 0806469
            </Text>
            <Text color="#0874D1" fontSize="small">
              Email
            </Text>
            <Text color="white" fontSize="large">
              hello@atom1.ai
            </Text>
            <Text color="#0874D1" fontSize="small">
              Address
            </Text>
            <Text color="white" fontSize="large">
              Karachi
            </Text>
            <Text color="white" fontSize="large">
              Pakistan, PK
            </Text>
          </VStack>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" color="white" fontSize="2xl" mt={5}>
            Navigate to
          </Text>
          <VStack spacing={1}>
            {/* <Link
              as={RouterLink}
              to="/careers"
              color={"#0874D1"}
              _hover={{ color: "white" }}
            >
              /Careers
            </Link> */}
            <Link
              as={RouterLink}
              to="/blog"
              color={"#0874D1"}
              _hover={{ color: "white" }}
            >
              /Blog
            </Link>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Footer;
