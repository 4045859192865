// SlideshowSection.js
import React from "react";
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import backgroundImage from "../images/Cover2.png";

const Landing = () => {
  const handleConsultClick = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/saaad07x/atom1-consultation", // Replace with your Calendly link
    });
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      backgroundImage={`url(${backgroundImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
      color="white"
      textAlign="center"
      position="relative"
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Heading as="h1" size="3xl" mb={4}>
          Welcome to Atom1!
        </Heading>
        <Text fontSize="xl">
          We Empower Your Business,{" "}
          <Text as="span" color="#0874D1">
            Atom by Atom
          </Text>
        </Text>
        <Button
          mt={5}
          borderRadius={25}
          display={{ base: "inline-flex", md: "inline-flex" }}
          fontSize={"sm"}
          fontWeight={600}
          color={"black"}
          bg={"#0874D1"}
          _hover={{
            color: "#FFF",
          }}
          onClick={handleConsultClick}
        >
          Free Consult
        </Button>
        <Text color="#0874D1" fontSize="xs" mt={2}>
          *Click on the button above for free consultation
        </Text>
      </Box>
    </Flex>
  );
};

export default Landing;
