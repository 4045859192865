// src/App.js
import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./theme"; // Import your custom theme
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppBar from "./components/AppBar";
import Home from "./pages/Home";
import OurWork from "./pages/OurWork";
import Blog from "./pages/Blog";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFoundPage"; // Import the NotFound component
import CareerOpening from "./pages/CareerOprining";
import DropResume from "./pages/DropResume";
import ScrollToTop from "./components/ScrollToTop"; // Import the ScrollToTop component

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <ScrollToTop />
        <AppBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/work" element={<OurWork />} />
          <Route path="/career" element={<CareerOpening />} />
          <Route path="/dropResume" element={<DropResume />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;
