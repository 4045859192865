import React from "react";
import { Box, Container, Image, Text, VStack, Center } from "@chakra-ui/react";
import CareerEmail from "../images/careerEmail.png";

const CareerOpening = () => {
  return (
    <Box minH="100vh" bg="gray.50" display="flex" alignItems="center">
      <Container maxW="container.md" textAlign="center">
        <VStack spacing={6}>
          <Image src={CareerEmail} alt="No job openings" borderRadius="md" />
          <Text fontSize="2xl" fontWeight="bold">
            No Job Openings at the Moment! :(
          </Text>
          <Text fontSize="lg">
            But you can drop your resume at
            <Text as="span" color="blue.500">
              {" "}
              hello@atom1.ai
            </Text>
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default CareerOpening;
