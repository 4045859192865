import React, { useState } from "react";
import {
  Box,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Image,
  Text,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useMediaQuery } from "@chakra-ui/react";
import { blogsData } from "../data/blogsData";

const Blog = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isSmallScreen] = useMediaQuery("(max-width: 30em)");

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
  };

  const handleBackClick = () => {
    setSelectedBlog(null);
  };

  return (
    <Box minHeight="100vh" py={40}>
      <Heading as="h2" size="xl" textAlign="left" py={4} px={10}>
        Blog {/* Main title */}
        <Text fontSize="lg" fontWeight="light" color="gray.500" mt={2}>
          {/* Subtitle */}
          Explore a collection of insightful articles on various topics.
        </Text>
      </Heading>
      {!isSmallScreen && (
        <Flex justifyContent="center">
          <Breadcrumb separator=">" fontSize="sm" mb={6}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/" color="#0874D1">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            {selectedBlog ? (
              <>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    onClick={handleBackClick}
                    to="/blog"
                    color="#0874D1"
                  >
                    Blogs{" ."}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink color="#000">
                    {" " + selectedBlog.title}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </>
            ) : (
              <BreadcrumbItem>
                <BreadcrumbLink color="#0874D1">Blog</BreadcrumbLink>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </Flex>
      )}
      {selectedBlog ? (
        <Box
          minW={"sm"}
          maxW="5xl"
          mx="auto"
          borderRadius="lg"
          overflow="hidden"
          p={10}
        >
          <Flex justify="flex-start" mb={4}>
            <Text
              aria-label="Back"
              icon={<ArrowBackIcon />}
              onClick={handleBackClick}
              color={"#0874D1"}
              bg={"white"}
              cursor="pointer"
            >
              {"⬅ Go Back"}
            </Text>
          </Flex>
          <Image
            borderRadius={15}
            src={selectedBlog.image}
            alt={selectedBlog.title}
            h={isSmallScreen ? 200 : 500}
          />

          <Box p="6">
            <VStack align="start" spacing={2}>
              <Text fontSize="sm" color="#0874D1">
                {selectedBlog.category}
              </Text>
              <Heading
                fontSize={isSmallScreen ? "20px" : "40px"}
                fontWeight="bold"
              >
                {selectedBlog.title}
              </Heading>
              <Text fontSize="sm" color="gray.500">
                By {selectedBlog.author} | {selectedBlog.date}
              </Text>
              {selectedBlog.description.split("\n").map((paragraph, index) => (
                <Text key={index}>
                  {paragraph.startsWith("-") ? (
                    <ul style={{ paddingLeft: "1rem", listStyleType: "none" }}>
                      {paragraph.split("\n").map((item, i) => (
                        <li
                          key={i}
                          style={{ marginBottom: "0.5rem", color: "#0874D1" }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    paragraph
                  )}
                </Text>
              ))}
              <Flex justify="center" mt={4}>
                <IconButton
                  aria-label="Back"
                  icon={<ArrowBackIcon />}
                  onClick={handleBackClick}
                  color={"#0874D1"}
                  bg={"black"}
                />
              </Flex>
            </VStack>
          </Box>
        </Box>
      ) : (
        <Flex
          justifyContent={{ base: "center", lg: "flex-start" }}
          flexWrap="wrap"
          mx={{ base: "auto", lg: "10" }}
        >
          {blogsData.map((blog) => (
            <Box
              key={blog.id}
              maxW="sm"
              minW={"sm"}
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              m={4}
              onClick={() => handleBlogClick(blog)}
              cursor="pointer"
              position="relative"
              minH={200}
            >
              <Image src={blog.image} alt={blog.title} minH={200} w="100%" />
              <Box p="6">
                <VStack align="start" spacing={2}>
                  <Text fontSize="sm" color="#0874D1">
                    {blog.category}
                  </Text>
                  <Heading fontSize="l">{blog.title}</Heading>
                  <Text fontSize="sm" color="gray.500">
                    By {blog.author} | {blog.date}
                  </Text>
                </VStack>
              </Box>
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default Blog;
