import React from 'react';
import Landing from '../sections/Landing';
import About from '../sections/About';
import Projects from '../sections/Projects';
import Services from '../sections/Services';

const Home = () => {
  return (
    <>
      <Landing />
      <Services />
      <About />
      <Projects />
      {/* <About /> */}

    </>
  );
};

export default Home;