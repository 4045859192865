import { extendTheme } from '@chakra-ui/react';

const fonts = {
  body: `'CenturyGothic-1', sans-serif`, // Replace 'Century Gothic 1' with the actual font name
  heading: `'CenturyGothic-1', sans-serif`, // Can be a different font if you prefer
};

const customTheme = extendTheme({ fonts });

export default customTheme;
