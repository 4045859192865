// src/pages/NotFound.js
import React from "react";
import { Flex, Heading, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh"
      textAlign="center"
      px={6}
    >
      <Heading as="h1" size="2xl" mb={4}>
        404
      </Heading>
      <Text fontSize="xl" mb={4}>
        Oops! The page you are looking for does not exist.
      </Text>
      <Button
        as={Link}
        to="/"
        colorScheme="white"
        bg={"#0874D1"}
        variant="solid"
      >
        Go to Home
      </Button>
    </Flex>
  );
};

export default NotFound;
