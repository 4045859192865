// Dummy data for blogs
// import SoftwareEngineeringImage from "../images/SoftwareEngineering.png";
import AIFuture from "../images/AI.png";

export const blogsData = [
  {
    id: 1,
    category: "AI",
    title:
      "AI and the future of work: Navigating Employment Trends, Job Displacement and Skill Requirements",
    date: "April 20, 2024",
    author: "John Doe",
    description: `Imagine a world where machines can think, learn, and perform tasks just like humans. That's the promise and potential of artificial intelligence, or AI. But what does this mean for the jobs we do and the way we work?

      Well, some experts predict that AI could lead to significant changes in employment trends. Jobs that involve repetitive tasks, like assembly line work or data entry, might be taken over by machines. This could result in job displacement for some workers, as their roles become automated.
      
      But it's not all doom and gloom. AI also opens up new possibilities for innovation and job creation. For example, as machines handle routine tasks, humans can focus on more creative and complex work that requires empathy, critical thinking, and problem-solving skills.
      
      However, to thrive in this evolving landscape, workers will need to adapt and acquire new skills. The workforce of the future may require a combination of technical skills, like programming and data analysis, as well as soft skills, like communication and adaptability. Lifelong learning and upskilling will be crucial for staying competitive in a world where technology is constantly evolving.
      
      Moreover, AI could lead to the emergence of entirely new industries and job roles that we haven't even imagined yet. From AI trainers to ethicists who ensure that AI systems are used responsibly, there will be opportunities for individuals to shape the future of work in exciting ways.
      
      To sum it up, while AI may bring about changes in employment patterns and job roles, it also presents opportunities for innovation and growth. By embracing lifelong learning and developing a diverse set of skills, individuals can navigate the evolving landscape of work and thrive in the age of artificial intelligence.`,
    image: AIFuture,
  },
  // {
  //   id: 2,
  //   category: "SE",
  //   title:
  //     "The Software Engineer's Guide to the Unforeseen: Building for a Chaotic World",
  //   date: "April 22, 2024",
  //   author: "Jane Smith",
  //   description: `The software we build shapes our world. From the apps in our pockets to the complex systems that power our infrastructure, our code dictates how we interact and operate. But the world throws curveballs. Unforeseen events, from power outages to security breaches, can cripple even the most meticulously crafted software.

  //   This is where "The Software Engineer's Guide to the Unforeseen" comes in. This guide isn't about writing perfect code (because let's face it, perfection is an illusion). It's about building software that can weather the storms, adapt to the unexpected, and keep functioning when the world throws you a curveball.

  //   What You'll Learn:

  //   - Embracing Antifragility: Move beyond resilience – we'll explore how to build software that actually thrives in the face of chaos.
  //   - The Art of Chaos Engineering: Learn how to simulate failure scenarios and proactively identify weak points in your systems.
  //   - Building for Maintainability: Complexity is the enemy of resilience. Discover strategies to keep your code clean, modular, and easy to adapt.
  //   - The Power of Observability: Gain real-time insights into your system's health. Learn how to monitor for anomalies and identify potential issues before they become outages.
  //   - Shifting Left in Security: Security isn't an afterthought. We'll delve into techniques for integrating security considerations into every stage of the development lifecycle.

  //   This guide is for you if:

  //   - You're tired of building software that crumbles at the first sign of trouble.
  //   - You want to create systems that are robust, adaptable, and secure.
  //   - You understand that the only constant is change, and your software needs to be ready for it.

  //   The future is unpredictable, but by embracing the unexpected, we can build software that's ready for anything. This guide will equip you with the tools and strategies to navigate the chaos and emerge victorious.`,
  //   image: AIFuture,
  // },
  // {
  //   id: 3,
  //   category: "Others",
  //   title: "Exploring Quantum Computing",
  //   date: "April 25, 2024",
  //   author: "Alice Johnson",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat ex vel mollis faucibus. Fusce porta ex eu odio vehicula fringilla. Integer vel lorem vel tortor fermentum fermentum.",
  //   image: AIFuture,
  // },
  // {
  //   id: 3,
  //   category: "Others",
  //   title: "Exploring Quantum Computing",
  //   date: "April 25, 2024",
  //   author: "Alice Johnson",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat ex vel mollis faucibus. Fusce porta ex eu odio vehicula fringilla. Integer vel lorem vel tortor fermentum fermentum.",
  //   image: AIFuture,
  // },

  // {
  //   id: 3,
  //   category: "Others",
  //   title: "Exploring Quantum Computing",
  //   date: "April 25, 2024",
  //   author: "Alice Johnson",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat ex vel mollis faucibus. Fusce porta ex eu odio vehicula fringilla. Integer vel lorem vel tortor fermentum fermentum.",
  //   image: AIFuture,
  // },
  // {
  //   id: 3,
  //   category: "Others",
  //   title: "Exploring Quantum Computing",
  //   date: "April 25, 2024",
  //   author: "Alice Johnson",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed feugiat ex vel mollis faucibus. Fusce porta ex eu odio vehicula fringilla. Integer vel lorem vel tortor fermentum fermentum.",
  //   image: AIFuture,
  // },
];
