import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Grid,
  Image,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import Values from "../images/1.png";
import Vision from "../images/2.png";
import Mission from "../images/3.png";

const AboutUsSection = () => {
  const bgColor = useColorModeValue("gray.100", "gray.900");

  return (
    <Box minHeight="100vh" bg={"black"} padding={[10, 8, 12, 16]}>
      <Flex justifyContent="center" alignItems="center" direction="column">
        <Heading as="h2" size="xl" textAlign="center" pb={8} color={"#0874D1"}>
          About Atom1
        </Heading>
        <Text fontSize="lg" textAlign="center" maxW="7xl" color={"#FFF"}>
          We are a passionate team driven by a shared mission to bridge the gap
          between cutting-edge artificial intelligence and innovative software
          development. We believe in the power of AI to revolutionize the way we
          build and interact with technology.
        </Text>
      </Flex>

      <Grid
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        mt={20}
        gap={6}
        mb={16}
      >
        <Stack alignItems={"center"} spacing={4}>
          <Image
            src={Values} // Replace with your image
            alt="Core Value 1 Icon"
            borderRadius="full"
            boxSize="100px"
          />
          <Heading as="h4" size="md" color={"#0874D1"}>
            Our Values
          </Heading>
          <Text textAlign={"center"} color={"#FFF"}>
            At Atom1, our values are the guiding principles that shape our
            identity and define our culture. Integrity, innovation,
            collaboration, and excellence are at the core of everything we do.
            Integrity ensures honesty and transparency in all our interactions.
            Innovation drives us to constantly explore new ideas and push the
            boundaries of what's possible. Collaboration fosters teamwork and
            diversity of thought, leading to better outcomes. Excellence is our
            commitment to delivering quality solutions that exceed expectations
            and drive success for our clients.
          </Text>
        </Stack>
        <Stack alignItems={"center"} spacing={4}>
          <Image
            src={Vision} // Replace with your image
            alt="Core Value 2 Icon"
            borderRadius="full"
            boxSize="100px"
          />
          <Heading as="h4" size="md" color={"#0874D1"}>
            Our Vision
          </Heading>
          <Text textAlign={"center"} color={"#FFF"}>
            Atom1 envisions a future where technology transforms businesses and
            enhances human experiences. Our vision is to be at the forefront of
            this transformation, leveraging advanced software and AI solutions
            to drive innovation and create value for our clients. We see a world
            where businesses thrive in a digital ecosystem powered by
            cutting-edge technology, where AI enables smarter decision-making
            and drives sustainable growth. Through our vision, we aim to inspire
            progress, empower businesses, and make a positive impact on society.
          </Text>
        </Stack>
        <Stack alignItems={"center"} spacing={4}>
          <Image
            src={Mission} // Replace with your image
            alt="Core Value 3 Icon"
            borderRadius="full"
            boxSize="100px"
          />
          <Heading as="h4" size="md" color={"#0874D1"}>
            Our Mission
          </Heading>
          <Text textAlign={"center"} color={"#FFF"}>
            Atom1's mission is to empower businesses with transformative
            software and AI solutions that drive growth and success. We are
            dedicated to understanding our clients' unique challenges and
            delivering tailored solutions that address their specific needs. Our
            mission is to be a trusted partner, guiding businesses through their
            digital transformation journey with expertise, integrity, and
            collaboration. By combining technical excellence with a
            customer-centric approach, we strive to create long-lasting
            partnerships and deliver measurable value to our clients and
            communities.
          </Text>
        </Stack>
      </Grid>

      <Flex justifyContent="center">
        <Text textAlign={"center"} color={"#FFF"}>
          Let's build the future together!
        </Text>
      </Flex>
    </Box>
  );
};

export default AboutUsSection;
