import React from "react";
import { Box, Container, Image, Text, VStack, Center } from "@chakra-ui/react";
import UnderConstruction from "../images/UnderConstruction.png";

const DropResume = () => {
  return (
    <Box minH="100vh" bg="gray.50" display="flex" alignItems="center">
      <Container maxW="container.md" textAlign="center">
        <VStack spacing={6}>
          <Image
            src={UnderConstruction}
            alt="No job openings"
            borderRadius="md"
          />
          <Text fontSize="2xl" fontWeight="bold">
            This page is underconstruction! Please drop your resume at
            <Text as="span" color="blue.500">
              {" "}
              hello@atom1.ai
            </Text>
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default DropResume;
